import * as React from "react";

const TypoSVG = (props) => (
  <svg
    width="41.792992mm"
    height="32.841042mm"
    viewBox="0 0 41.792992 32.841042"
    id="svg104"
    {...props}
  >
    <defs id="defs101" />
    <g
      label="Camada 1"
      groupmode="layer"
      id="layer1"
      transform="translate(-102.32008,-124.59446)"
    >
      <g
        aria-label="NEom"
        id="text2944"
        style={{
          fontSize: "30.2629px",
          lineHeight: 1.25,
          fill: props.fill,
          strokeWidth: 0.756575,
        }}
        transform="translate(14.59637,18.264142)"
      >
        <path
          d="m 87.74581,126.90909 c 0,0.39342 0.21184,0.60526 0.605258,0.60526 h 1.513145 c 0.393417,0 0.605258,-0.21184 0.605258,-0.60526 v -7.7473 c 0,-0.27237 0.272366,-0.33289 0.332891,-0.0605 l 1.906563,7.92888 c 0.06053,0.30263 0.363155,0.48421 0.696047,0.48421 h 1.603933 c 0.393418,0 0.605258,-0.21184 0.605258,-0.60526 v -19.97351 c 0,-0.39342 -0.21184,-0.60526 -0.605258,-0.60526 H 93.49576 c -0.393417,0 -0.605258,0.21184 -0.605258,0.60526 v 8.35256 c 0,0.27237 -0.272366,0.33289 -0.332891,0.0605 l -1.906563,-8.53414 c -0.06053,-0.30263 -0.363155,-0.48421 -0.696047,-0.48421 h -1.603933 c -0.393418,0 -0.605258,0.21184 -0.605258,0.60526 z"
          style={{
            fontFamily: "Cache",
            InkscapeFontSpecification: "'Cache, Normal'",
          }}
          id="path55838"
        />
        <path
          d="m 98.6404,127.51435 h 6.65784 c 0.33289,0 0.60526,-0.27237 0.60526,-0.60526 v -1.51314 c 0,-0.33289 -0.27237,-0.60526 -0.60526,-0.60526 h -4.08549 c -0.3329,0 -0.60526,-0.27237 -0.60526,-0.60526 v -5.29601 c 0,-0.33289 0.27236,-0.60525 0.60526,-0.60525 h 3.1776 c 0.33289,0 0.60526,-0.27237 0.60526,-0.60526 v -1.51315 c 0,-0.33289 -0.27237,-0.60525 -0.60526,-0.60525 h -3.1776 c -0.3329,0 -0.60526,-0.27237 -0.60526,-0.60526 v -5.29601 c 0,-0.33289 0.27236,-0.60526 0.60526,-0.60526 h 4.08549 c 0.33289,0 0.60526,-0.27236 0.60526,-0.60526 v -1.51314 c 0,-0.33289 -0.27237,-0.60526 -0.60526,-0.60526 H 98.6404 c -0.332892,0 -0.605258,0.27237 -0.605258,0.60526 v 19.97351 c 0,0.33289 0.272366,0.60526 0.605258,0.60526 z"
          style={{
            fontFamily: "Cache",
            InkscapeFontSpecification: "'Cache, Normal'",
          }}
          id="path55840"
        />
        <path
          d="m 108.32447,125.42621 v 1.51315 c 0,0.39341 0.21184,0.60525 0.60526,0.60525 h 6.35521 c 0.39342,0 0.60526,-0.21184 0.60526,-0.60525 v -1.51315 c 0,-0.39342 -0.21184,-0.60526 -0.60526,-0.60526 h -6.35521 c -0.39342,0 -0.60526,0.21184 -0.60526,0.60526 z m 3.08682,-3.38944 h 1.39209 c 1.78551,0 3.08682,-1.30131 3.08682,-3.08682 v -9.53281 c 0,-1.78551 -1.30131,-3.08682 -3.08682,-3.08682 h -1.39209 c -1.78551,0 -3.08682,1.30131 -3.08682,3.08682 v 9.53281 c 0,1.78551 1.30131,3.08682 3.08682,3.08682 z m -0.36315,-3.32892 v -9.04861 c 0,-0.33289 0.27236,-0.60526 0.60525,-0.60526 h 0.90789 c 0.33289,0 0.60526,0.27237 0.60526,0.60526 v 9.04861 c 0,0.33289 -0.27237,0.60526 -0.60526,0.60526 h -0.90789 c -0.33289,0 -0.60525,-0.27237 -0.60525,-0.60526 z"
          style={{
            fontFamily: "Cache",
            InkscapeFontSpecification: "'Cache, Normal'",
          }}
          id="path55842"
        />
        <path
          d="m 118.31123,126.90909 c 0,0.39342 0.21184,0.60526 0.60526,0.60526 h 1.51314 c 0.39342,0 0.60526,-0.21184 0.60526,-0.60526 V 116.7105 c 0,-0.27237 0.21184,-0.3329 0.33289,-0.0605 l 1.75525,4.32759 c 0.12105,0.3329 0.36315,0.48421 0.69605,0.48421 h 0.15131 c 0.33289,0 0.575,-0.15131 0.69605,-0.48421 l 1.78551,-4.38812 c 0.12105,-0.27236 0.33289,-0.21184 0.33289,0.0605 v 10.25912 c 0,0.39342 0.21184,0.60526 0.60526,0.60526 h 1.51314 c 0.39342,0 0.60526,-0.21184 0.60526,-0.60526 v -19.97351 c 0,-0.39342 -0.21184,-0.60526 -0.60526,-0.60526 h -1.60393 c -0.33289,0 -0.60526,0.18158 -0.69605,0.48421 l -2.39077,8.53414 c -0.0605,0.2421 -0.33289,0.2421 -0.39341,0 l -2.57235,-8.53414 c -0.0908,-0.30263 -0.36316,-0.48421 -0.69605,-0.48421 h -1.63419 c -0.39342,0 -0.60526,0.21184 -0.60526,0.60526 z"
          style={{
            fontFamily: "Cache",
            InkscapeFontSpecification: "'Cache, Normal'",
          }}
          id="path55844"
        />
      </g>
      <g
        aria-label="NETWoRKS"
        id="text40130"
        style={{
          fontSize: "15.0335px",
          lineHeight: 1.25,
          fill: props.fill,
          strokeWidth: 0.375837,
        }}
        transform="translate(14.59637,18.264142)"
      >
        <path
          d="m 87.723708,138.85566 c 0,0.19543 0.105234,0.30067 0.30067,0.30067 h 0.751675 c 0.195435,0 0.30067,-0.10524 0.30067,-0.30067 v -3.84858 c 0,-0.1353 0.135301,-0.16537 0.165368,-0.0301 l 0.947111,3.93878 c 0.03007,0.15034 0.180402,0.24054 0.34577,0.24054 h 0.796776 c 0.195435,0 0.30067,-0.10524 0.30067,-0.30067 v -9.92211 c 0,-0.19544 -0.105235,-0.30067 -0.30067,-0.30067 h -0.751675 c -0.195436,0 -0.30067,0.10523 -0.30067,0.30067 v 4.14924 c 0,0.1353 -0.135302,0.16537 -0.165369,0.0301 l -0.94711,-4.23945 c -0.03007,-0.15033 -0.180402,-0.24053 -0.345771,-0.24053 h -0.796775 c -0.195436,0 -0.30067,0.10523 -0.30067,0.30067 z"
          style={{
            fontFamily: "Cache",
            InkscapeFontSpecification: "'Cache, Normal'",
          }}
          id="path55847"
        />
        <path
          d="m 93.135741,139.15633 h 3.30737 c 0.165369,0 0.30067,-0.13531 0.30067,-0.30067 v -0.75168 c 0,-0.16537 -0.135301,-0.30067 -0.30067,-0.30067 h -2.029522 c -0.165369,0 -0.30067,-0.1353 -0.30067,-0.30067 v -2.63086 c 0,-0.16537 0.135301,-0.30067 0.30067,-0.30067 h 1.578517 c 0.165369,0 0.30067,-0.1353 0.30067,-0.30067 v -0.75168 c 0,-0.16536 -0.135301,-0.30067 -0.30067,-0.30067 h -1.578517 c -0.165369,0 -0.30067,-0.1353 -0.30067,-0.30067 v -2.63086 c 0,-0.16537 0.135301,-0.30067 0.30067,-0.30067 h 2.029522 c 0.165369,0 0.30067,-0.1353 0.30067,-0.30067 v -0.75167 c 0,-0.16537 -0.135301,-0.30067 -0.30067,-0.30067 h -3.30737 c -0.165368,0 -0.30067,0.1353 -0.30067,0.30067 v 9.92211 c 0,0.16536 0.135302,0.30067 0.30067,0.30067 z"
          style={{
            fontFamily: "Cache",
            InkscapeFontSpecification: "'Cache, Normal'",
          }}
          id="path55849"
        />
        <path
          d="m 98.247105,129.98589 h 0.676507 c 0.165369,0 0.30067,0.1353 0.30067,0.30067 v 8.5691 c 0,0.16536 0.135302,0.30067 0.30067,0.30067 h 0.751678 c 0.16537,0 0.30067,-0.13531 0.30067,-0.30067 v -8.5691 c 0,-0.16537 0.1353,-0.30067 0.30067,-0.30067 h 0.6765 c 0.16537,0 0.30067,-0.1353 0.30067,-0.30067 v -0.75167 c 0,-0.16537 -0.1353,-0.30067 -0.30067,-0.30067 h -3.307365 c -0.165369,0 -0.30067,0.1353 -0.30067,0.30067 v 0.75167 c 0,0.16537 0.135301,0.30067 0.30067,0.30067 z"
          style={{
            fontFamily: "Cache",
            InkscapeFontSpecification: "'Cache, Normal'",
          }}
          id="path55851"
        />
        <path
          d="m 103.0578,128.96361 1.00724,9.92211 c 0.015,0.18041 0.1353,0.27061 0.31571,0.27061 h 1.26281 c 0.1804,0 0.30067,-0.0902 0.3157,-0.27061 l 0.21047,-2.39032 c 0,-0.0301 0.0752,-0.0301 0.0752,0 l 0.24054,2.39032 c 0.015,0.18041 0.1353,0.27061 0.3157,0.27061 h 1.26281 c 0.18041,0 0.30067,-0.0902 0.31571,-0.27061 l 0.85691,-9.92211 c 0.015,-0.19543 -0.0752,-0.33073 -0.27061,-0.33073 h -0.75167 c -0.1804,0 -0.30067,0.0902 -0.31571,0.2706 l -0.42093,4.73555 c -0.015,0.1353 -0.16537,0.1353 -0.1804,0 l -0.48108,-4.72052 c 0,-0.16537 -0.0902,-0.28563 -0.2706,-0.28563 h -0.79678 c -0.1804,0 -0.2706,0.12026 -0.2706,0.28563 l -0.42094,4.72052 c -0.015,0.1353 -0.16536,0.1353 -0.1804,0 l -0.48107,-4.73555 c -0.015,-0.1804 -0.1353,-0.2706 -0.3157,-0.2706 h -0.75168 c -0.19543,0 -0.28564,0.1353 -0.2706,0.33073 z"
          style={{
            fontFamily: "Cache",
            InkscapeFontSpecification: "'Cache, Normal'",
          }}
          id="path55853"
        />
        <path
          d="m 110.42421,138.11901 v 0.75168 c 0,0.19544 0.10523,0.30067 0.30067,0.30067 h 3.15703 c 0.19544,0 0.30067,-0.10523 0.30067,-0.30067 v -0.75168 c 0,-0.19543 -0.10523,-0.30067 -0.30067,-0.30067 h -3.15703 c -0.19544,0 -0.30067,0.10524 -0.30067,0.30067 z m 1.53342,-1.68375 h 0.69154 c 0.88697,0 1.53341,-0.64644 1.53341,-1.53341 v -4.73556 c 0,-0.88697 -0.64644,-1.53341 -1.53341,-1.53341 h -0.69154 c -0.88698,0 -1.53342,0.64644 -1.53342,1.53341 v 4.73556 c 0,0.88697 0.64644,1.53341 1.53342,1.53341 z m -0.18041,-1.65368 v -4.49502 c 0,-0.16537 0.1353,-0.30067 0.30067,-0.30067 h 0.45101 c 0.16537,0 0.30067,0.1353 0.30067,0.30067 v 4.49502 c 0,0.16537 -0.1353,0.30067 -0.30067,0.30067 h -0.45101 c -0.16537,0 -0.30067,-0.1353 -0.30067,-0.30067 z"
          style={{
            fontFamily: "Cache",
            InkscapeFontSpecification: "'Cache, Normal'",
          }}
          id="path55855"
        />
        <path
          d="m 115.38526,138.85566 c 0,0.16536 0.13531,0.30067 0.30067,0.30067 h 0.75168 c 0.16537,0 0.30067,-0.13531 0.30067,-0.30067 v -3.96885 c 0,-0.16537 0.1353,-0.30067 0.30067,-0.30067 h 0.60134 c 0.16537,0 0.30067,0.1353 0.30067,0.30067 v 3.95381 c 0,0.16537 0.1353,0.31571 0.30067,0.31571 h 0.73664 c 0.1804,0 0.3157,-0.13531 0.3157,-0.31571 v -3.47274 c 0,-0.5863 -0.21046,-1.09744 -0.55624,-1.47328 0.34578,-0.37584 0.55624,-0.88698 0.55624,-1.47328 v -2.25503 c 0,-0.88697 -0.64644,-1.53341 -1.53341,-1.53341 h -2.07463 c -0.16536,0 -0.30067,0.1353 -0.30067,0.30067 z m 1.35302,-5.93824 v -2.63086 c 0,-0.16537 0.1353,-0.30067 0.30067,-0.30067 h 0.60134 c 0.16537,0 0.30067,0.1353 0.30067,0.30067 v 2.63086 c 0,0.16537 -0.1353,0.30067 -0.30067,0.30067 h -0.60134 c -0.16537,0 -0.30067,-0.1353 -0.30067,-0.30067 z"
          style={{
            fontFamily: "Cache",
            InkscapeFontSpecification: "'Cache, Normal'",
          }}
          id="path55857"
        />
        <path
          d="m 120.49663,138.85566 c 0,0.16536 0.1353,0.30067 0.30067,0.30067 h 0.75167 c 0.16537,0 0.30067,-0.13531 0.30067,-0.30067 v -3.96885 c 0,-0.16537 0.1353,-0.30067 0.30067,-0.30067 h 0.60134 c 0.16537,0 0.30067,0.1353 0.30067,0.30067 v 3.95381 c 0,0.16537 0.1353,0.31571 0.30067,0.31571 h 0.73664 c 0.1804,0 0.31571,-0.13531 0.31571,-0.31571 v -3.47274 c 0,-0.5863 -0.21047,-1.09744 -0.55624,-1.47328 0.34577,-0.37584 0.55624,-0.88698 0.55624,-1.47328 v -3.48777 c 0,-0.16537 -0.13531,-0.30067 -0.30067,-0.30067 h -0.75168 c -0.16537,0 -0.30067,0.15033 -0.30067,0.3157 v 3.96884 c 0,0.16537 -0.1353,0.30067 -0.30067,0.30067 h -0.60134 c -0.16537,0 -0.30067,-0.1353 -0.30067,-0.30067 v -3.98387 c 0,-0.16537 -0.1353,-0.30067 -0.30067,-0.30067 h -0.75167 c -0.16537,0 -0.30067,0.1353 -0.30067,0.30067 z"
          style={{
            fontFamily: "Cache",
            InkscapeFontSpecification: "'Cache, Normal'",
          }}
          id="path55859"
        />
        <path
          d="m 125.60799,137.63794 c 0,0.88698 0.64644,1.53342 1.53342,1.53342 h 0.84187 c 0.88698,0 1.53342,-0.64644 1.53342,-1.53342 v -2.9165 c 0,-0.97717 -0.52617,-1.50335 -1.50335,-1.50335 h -0.75168 c -0.19543,0 -0.30067,-0.10523 -0.30067,-0.30067 v -2.64589 c 0,-0.16537 0.13531,-0.30067 0.30067,-0.30067 h 0.60134 c 0.16537,0 0.30067,0.1353 0.30067,0.30067 v 0.69154 c 0,0.19543 0.10524,0.30067 0.30067,0.30067 h 0.75168 c 0.19543,0 0.30067,-0.10524 0.30067,-0.30067 v -0.81181 c 0,-0.88698 -0.64644,-1.53342 -1.53342,-1.53342 h -0.84187 c -0.88698,0 -1.53342,0.64644 -1.53342,1.53342 v 2.9165 c 0,0.97718 0.52617,1.50335 1.50335,1.50335 h 0.75167 c 0.19544,0 0.30067,0.10523 0.30067,0.30067 v 2.64589 c 0,0.16537 -0.1353,0.30067 -0.30067,0.30067 h -0.60134 c -0.16536,0 -0.30067,-0.1353 -0.30067,-0.30067 v -0.69154 c 0,-0.19543 -0.10523,-0.30067 -0.30067,-0.30067 h -0.75167 c -0.19544,0 -0.30067,0.10524 -0.30067,0.30067 z"
          style={{
            fontFamily: "Cache",
            InkscapeFontSpecification: "'Cache, Normal'",
          }}
          id="path55861"
        />
      </g>
    </g>
  </svg>
);

export default TypoSVG;
